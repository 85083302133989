import {graphql, Link} from 'gatsby';
import React from 'react';

import {Button} from '../../../components/button';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';

export default ({data}: any) => (
    <Layout
        title="Continu het softwareproduct verbeteren en doorontwikkelen – Stap 4B van onze aanpak"
        description="Een softwareproduct is nooit af. Momentum en betrokkenheid moeten we vasthouden, en stagnatie vermijden. Daarom maken we afspraken over periodieke doorontwikkeling."
        ogImage="continue-doorontwikkeling.png"
        translatedPagePath="/en/approach/continuous-development/">
        <Link to="/nl/werkwijze/service/" className="body__nav body__nav--left" title="Aanpakstap 4A. Serveren">
            Vorige stap
        </Link>

        <Link
            to="/nl/werkwijze/capability-transfer/"
            className="body__nav body__nav--right"
            title="Aanpakstap 5. Transitie">
            Volgende stap
        </Link>

        <section className="section">
            <article className="content content--no-hero">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Continu <br /> verbeteren en <br /> door&shy;ontwikkelen
                        </h2>
                        <div className="display__number">4b</div>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Een software&shy;product is nooit af. Momentum en betrokkenheid moeten we vasthouden, en{' '}
                            stagnatie vermijden. Daarom maken we afspraken over periodieke door&shy;ontwikkeling.
                        </p>
                        <p>
                            Na lancering werken we door in hetzelfde iteratieve proces als tijdens de productrealisatie.{' '}
                            Nu kan continu op schaal <span className="highlight">gebruikers&shy;feedback</span>{' '}
                            verzameld worden. Hiermee kunnen we directer inspelen op de wensen van de eindgebruikers.
                        </p>
                        <p>
                            We <span className="highlight">optimaliseren</span> en{' '}
                            <span className="highlight">verbeteren</span> bestaande features en user experience, en{' '}
                            zorgen ervoor dat de codebase bijblijft met veranderingen aan fundamentele technologieën{' '}
                            waarop de software ontwikkeld is. Nog belangrijker&mdash;er worden{' '}
                            <span className="highlight">nieuwe features</span> ontworpen en gerealiseerd die het product{' '}
                            verder verrijken.
                        </p>
                        <p>
                            Na het afronden van een iteratie en het verwerken van feedback, lanceren we direct naar de{' '}
                            productieomgeving. Zo profiteren eindgebruikers vrijwel meteen en doorlopend van de verdere{' '}
                            ontwikkeling.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Iteraties van 1 &ndash; 2 weken</li>
                            <li className="ion-md-arrow-dropright">Vanaf 1 iteratie per maand</li>
                            <li className="ion-md-arrow-dropright">1 &ndash; 4 specialisten</li>
                        </ul>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button
                            url="/nl/werkwijze/capability-transfer"
                            name="Volgende stap: ontwikkelingstransitie"
                            title="Aanpakstap 5. Transitie"
                        />
                    </div>
                </div>
            </article>

            <Cta image={data.olav.childImageSharp.fixed} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }
    }
`;
